<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import PosRegionalsService from '@src/services/PosRegionalsService'
import importModal from '@views/importJob/importJobModal'
import FilterBar from '@src/components/FilterBar'
import posRegionalModal from '@views/posRegional/posRegionalModal.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import userChildrenFields from '@/src/fields/childrens/userChildrenFields.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        posRegionalModal,
        GridFormModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        CountTableButton,
        MoreTableButton,
    },
    mixins: [pagination, swalFeedback, userChildrenFields],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modal: {
                id: null,
                name: '',
                users: [],
            },
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.regional'),
                    sortField: 'name',
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filterText: '',
            additionalParameters: {
                with: ['users'],
            },
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.regional',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.regional',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewPosRegional() {
            return this.getI18n(i18nKey, 'BUTTONS.new_regional')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return PosRegionalsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        i18nPosRegional(modifier) {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.regional',
                modifier: modifier,
            })
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.posRegionalModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        openModalUsers(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridUsersModal.showModal())
        },
        replaceModalData(data) {
            Object.assign(this.modal, {
                id: data.id,
                name: data.name,
                users: [],
            })

            Object.assign(this.modal.users, data.users)
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>

                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'POSREGIONAL')"
                            :title="i18nNewPosRegional"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="point_of_sale_regional"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nPosRegional(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="users" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.users.length"
                                @onClick="openModalUsers(props.rowData)"
                            />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button
                                    @onClick="openModalToEdit(props.rowData)"
                                />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <pos-regional-modal
            ref="posRegionalModal"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridUsersModal"
            :modal="modal"
            :items="modal.users"
            :fields="userChildrenFields"
            :title="
                getI18nModified({
                    prefix: 'USERS',
                    suffix: 'TITLES.user',
                    modifier: 2,
                })
            "
        />
        <import-modal
            ref="importModal"
            :csv-type="'point_of_sale_regional'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TYPES.point_of_sale_regional',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
